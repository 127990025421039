import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from '../../../utils/api.services';
import axios from 'axios';
import platform from 'platform';
import { v4 as uuidv4 } from 'uuid';

// export const getCountryListApiCall = createAsyncThunk("countryList", async (submitData, { dispatch }) => {
//     try {
//         dispatch(setLoader(true))
//         const { data: countryList } = await API.countryListApi(submitData);
//         countryList?.map(country => {
//             country.label = country?.country_code;
//             country.value = country?.country_code;
//         });
//         dispatch(setLoader(false))
//         return countryList;
//     } catch (error) {
//         throw error;
//     }
// });

// export const getUserDetailsApiCall = createAsyncThunk("userDetails", async (submitData, { dispatch }) => {
//     try {
//         dispatch(setLoader(true))
//         const { data: userDetails } = await API.getUserDetailsAPI(submitData);
//         Cookies.set('dataCW', JSON.stringify(userDetails));
//         if (userDetails?.device_details?.user_type === 'employee') {
//             dispatch(setEmployeeTasksPermission(userDetails?.employee_permission?.filter(item => item?.type === 'Tasks')?.[0]));
//             dispatch(setEmployeeLeadsPermission(userDetails?.employee_permission?.filter(item => item?.type === 'Leads')?.[0]));
//             dispatch(setEmployeeTeamsPermission(userDetails?.employee_permission?.filter(item => item?.type === 'Teams')?.[0]));
//             dispatch(setEmployeeOrganizationsPermission(userDetails?.employee_permission?.filter(item => item?.type === 'Organizations')?.[0]));
//         }
//         dispatch(setUserRoleType(userDetails?.device_details?.user_type));
//         dispatch(setLoader(false))
//         return userDetails;
//     } catch (error) {
//         throw error;
//     }
// });

// export const getDeviceDetails = createAsyncThunk("deviceDetails", async (submitData, { dispatch }) => {
//     try {
//         dispatch(setLoader(true))
//         const deviceInfo = await platform.parse(navigator.userAgent);
//         const { data: deviceDetails } = await axios.get('https://api64.ipify.org?format=json');
//         const uuid = uuidv4();
//         let DATA = {
//             ip: deviceDetails?.ip,
//             device_name: deviceInfo?.os?.family,
//             model_name: deviceInfo?.description,
//             os_version: deviceInfo?.os?.version ? deviceInfo?.os?.version : deviceInfo?.version,
//             uuid
//         }
//         dispatch(setLoader(false));
//         return DATA;
//     } catch (error) {
//         throw error;
//     }
// });

const initialState = {
    isLoading: false,
    // userLanguage: 'en',
    // userRoleType: '',
    // deviceDetails: {
    //     data: null,
    //     error: null,
    // },
 
    socket: null,
    // audioRef: null,
    // countryList: {
    //     data: [],
    //     error: null,
    // },
    // userDetails: {
    //     data: null,
    //     error: null,
    // },
    // isModalOpen: {
    //     modalType: "",
    //     isOpen: false,
    //     data: null,
    // },
    // isOffcanvasOpen: {
    //     canvasType: "",
    //     isOpen: false,
    //     data: null,
    //     isEdit: false
    // },
    isToastOpen: {
        toastType: "",
        isOpen: false,
        data: null,
    },
}

const masterSlice = createSlice({
    name: 'MASTER',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.isLoading = action.payload;
        },
        // setModalStatus: (state, action) => {
        //     const { modalType, isOpen, data } = action.payload;
        //     state.isModalOpen.modalType = modalType;
        //     state.isModalOpen.isOpen = isOpen;
        //     state.isModalOpen.data = data;
        // },
        // setOffcanvasStatus: (state, action) => {
        //     const { canvasType, isOpen, data, isEdit } = action.payload;
        //     state.isOffcanvasOpen.canvasType = canvasType;
        //     state.isOffcanvasOpen.isOpen = isOpen;
        //     state.isOffcanvasOpen.data = data;
        //     state.isOffcanvasOpen.isEdit = isEdit;
        // },
        setToastStatus: (state, action) => {
            const { toastType, isOpen, data } = action.payload;
            state.isToastOpen.toastType = toastType;
            state.isToastOpen.isOpen = isOpen;
            state.isToastOpen.data = data;
        },
        // setUserLanguage: (state, action) => {
        //     state.userLanguage = action.payload;
        // },
        // setUserRoleType: (state, action) => {
        //     state.userRoleType = action.payload;
        // },
        setSocket: (state, action) => {
            state.socket = action.payload;
        },
    },
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(getCountryListApiCall.fulfilled, (state, action) => {
    //             state.countryList.data = action.payload;
    //         })
    //         .addCase(getCountryListApiCall.rejected, (state, action) => {
    //             state.countryList.error = action.error.message;
    //         })
    //         .addCase(getUserDetailsApiCall.fulfilled, (state, action) => {
    //             state.userDetails.data = action.payload;
    //         })
    //         .addCase(getUserDetailsApiCall.rejected, (state, action) => {
    //             state.userDetails.error = action.error.message;
    //         })
    //         .addCase(getDeviceDetails.fulfilled, (state, action) => {
    //             state.deviceDetails.data = action.payload;
    //         })
    //         .addCase(getDeviceDetails.rejected, (state, action) => {
    //             state.deviceDetails.error = action.error.message;
    //         });
    // },
});

export const { setLoader, setSocket, setToastStatus } = masterSlice.actions;
// export const { setLoader, setUserLanguage, setUserRoleType, setEmployeeTasksPermission, setEmployeeLeadsPermission, setEmployeeTeamsPermission, setEmployeeOrganizationsPermission, setCustomizeDashboardCard, setDashboardCard, setSocket, setAudioRef } = masterSlice.actions;
export default masterSlice.reducer;