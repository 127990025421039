import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router/index";
import { onMessage } from 'firebase/messaging';
import { messaging } from './utils/social.login';
import { toast } from "react-toastify";
// import CustomeToast from "./component/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import { setToastStatus } from "./pages/store/slices/masterSlice";
import { Toast, ToastContainer } from 'react-bootstrap'
import { setLoader } from './pages/store/slices/masterSlice';
import useVisibilityChange from './utils/useVisibilityChange';

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <Router />
      </BrowserRouter>
    </>
  );
}

export default App;

