const Constant = {

    APP_NAME: process.env.REACT_APP_NAME,
    API_KEY: process.env.REACT_APP_API_KEY,
    API_BASE_URL: process.env.REACT_APP_API_URL,
    KEY: process.env.REACT_APP_KEY,
    IV: process.env.REACT_APP_IV,
    APP_BASE_URL: process.env.REACT_APP_BASE_URL,
    APP_LOGO: "assets/imges/icons/tradepal_logo.svg",
   
    S3_BUCKET_ROOT: process.env.REACT_APP_S3_BUCKET_ROOT,
    S3_BUCKET_KEY: process.env.REACT_APP_S3_BUCKET_KEY,
    S3_BUCKET_SECRET: process.env.REACT_APP_S3_BUCKET_SECRET,
    S3_BUCKET_REGION: process.env.REACT_APP_S3_BUCKET_REGION,
    S3_BUCKET_NAME: process.env.REACT_APP_S3_BUCKET_NAME,

    REACT_APP_SOCKET_API_URL:process.env.REACT_APP_SOCKET_API_URL,
    REACT_APP_GOOGLE_PLACE_KEY:process.env.REACT_APP_GOOGLE_PLACE_KEY,
    
    REACT_APP_STRIPE_PUBLIC_KEY:process.env.REACT_APP_STRIPE_PUBLIC_KEY,

    // ---------------------------Code manage---------------------------------------

    SUCCESS: 1,
    INTERNAL_ERROR: 0,
    NO_DATA_FOUND: 2,
    DELETE_ACCOUNT: 3,
    UNAUTHORIZED: -1,
    COMPLATE_PROFILE: 4,
    SOCIAL_LOGIN: 14,

    // --------------------------Local storage credentials-----------------------------------------

    AUTH_KEY: 'user',
    FORGOTPASSWORDOTP: 'FPOTP',
    SIGNUPPASSWORDOTP: 'SOTP',
    LANGUAGE_KEY: 'language',
    THEME_KEY: 'theme',

    SEARCH_DELAY: 500,
    PER_PAGE_DATA: 10,
    COUNT_PER_PAGE: 10,
    APP_CURRENCY: '$',
    CHAR_LIMITS: 300,

}

export default Constant