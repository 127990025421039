import CryptoJS from "crypto-js";
import Constant from "../config/constant";

const secretKey = Constant.KEY;
const iv = Constant.IV;

// Encrypt function
function encrypt(data) {
  const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Hex.parse(secretKey), {
    iv: CryptoJS.enc.Hex.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
  return cipherText;
}

// Decrypt function
function decrypt(cipherText) {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, CryptoJS.enc.Hex.parse(secretKey), {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(plaintext);
  } catch (error) {
    return {}
  }

}

export { encrypt, decrypt };