import { Routes, Route, useNavigate, Link } from 'react-router-dom';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import Constant from '../config/constant';
import { decrypt } from '../utils/encrypt.decrypt';
import { UserContext } from '../Context/UserData';
import useVisibilityChange from '../utils/useVisibilityChange';
import { onMessage } from 'firebase/messaging';
import { useDispatch, useSelector } from 'react-redux';
import { messaging } from '../utils/social.login';
import { setToastStatus } from '../pages/store/slices/masterSlice';
import { toast } from "react-toastify";

// import DashboardLayout from '../layout';
// import NonProtectedRouts from '../utils/nonprotected.routs';
// import { useSelector } from 'react-redux';

export const Loadable = (Component) => (props) => {
    return (
        <Suspense>
            <Component {...props} />
        </Suspense>
    );
};

const Layout = Loadable(lazy(() => import("../layout")));
const Home = Loadable(lazy(() => import("../pages/home")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard")));
const Stock = Loadable(lazy(() => import("../pages/stock")));
const StockDetails = Loadable(lazy(() => import("../pages/stocks_details")));
const Profile = Loadable(lazy(() => import("../pages/profile")));
const Update = Loadable(lazy(() => import("../pages/update")));
const Setting = Loadable(lazy(() => import("../pages/setting")));
const WatchList = Loadable(lazy(() => import("../pages/watch_list")));
const Bookmark = Loadable(lazy(() => import("../pages/bookmark")));
const BookmarkDetails = Loadable(lazy(() => import("../pages/bookmark_details")));
const News = Loadable(lazy(() => import("../pages/news")));
const NewsDetails = Loadable(lazy(() => import("../pages/news/NewsDetails")));
const Request = Loadable(lazy(() => import("../pages/social_connect")));
const BlockUser = Loadable(lazy(() => import("../pages/social_connect/BlockUser")));
const General = Loadable(lazy(() => import("../pages/general")));
const Notification = Loadable(lazy(() => import("../pages/notification")));
const Chat = Loadable(lazy(() => import("../pages/chat")));
const ChatDetails = Loadable(lazy(() => import("../pages/chat/Message")));
const Socket = Loadable(lazy(() => import("../pages/socket")));
const Connection = Loadable(lazy(() => import("../pages/connection")));
const PostDetail = Loadable(lazy(() => import("../pages/post/PostDetail")));
const WaitingPage = Loadable(lazy(() => import("../pages/waiting_page/index")));
const TermsCondition = Loadable(lazy(() => import("../pages/general_pages/TermsCondition")));
const PrivacyPolicy = Loadable(lazy(() => import("../pages/general_pages/PrivacyPolicy")));
const AboutUs = Loadable(lazy(() => import("../pages/general_pages/AboutUs")));

const PageNotFound = Loadable(lazy(() => import("./PageNotFound")));

const Router = () => {
    const isForeground = useVisibilityChange();
    let { isToastOpen } = useSelector((state) => state.master);
    const { is_login } = useContext(UserContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleToastClick = (notificationData) => {
        if (notificationData?.tag === 'request_response') {
            navigate('/notification');
        } 
        else if (notificationData?.tag === 'comment') {
            navigate(`/post-detail/${notificationData?.action_id}`);
        }
        else if (notificationData?.tag === 'send_request') {
            navigate(`/request`);
        }
      };

    useEffect(() => {
        try {
            onMessage(messaging, (payload) => {
                if (isForeground) {
                    const notification=JSON.parse(payload?.data?.data);
                    toast.info(
                        <div onClick={() => handleToastClick(notification)} style={{ cursor: 'pointer' }}>
                            <strong>{notification?.title || 'Notification'}</strong>
                            <br />
                            <strong>{notification?.body || 'You have a new notification.'}</strong>
                        </div>,
                        {
                          position: 'top-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          icon: false,
                        }
                      );
                    // dispatch(setToastStatus({ toastType: 'NOTIFICATION_SEND', isOpen: true, data: JSON.parse(payload.data.data), }));
                } else {
                     console.log('onMessage else :', isForeground);
                }
            });
        } catch (error) {
            console.log('payload error :', error);
        }
    }, [is_login,isForeground]);

    return (
        <>
            <Routes>
                {!is_login && <Route path="/" element={<Home />} />}
                {is_login &&
                    <>
                        <Route element={<Socket />} >
                            <Route element={<Layout />} >
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/stocks" element={<Stock />} />
                                <Route path="/stock-details/:symbol" element={<StockDetails />} />
                                <Route path="/stock-details" element={<StockDetails />} />
                                <Route path="/user-profile" element={<Profile />} />
                                <Route path="/edit-profile" element={<Update />} />
                                <Route path="/setting" element={<Setting />} />
                                <Route path="/watch-list" element={<WatchList />} />
                                <Route path="/bookmark" element={<Bookmark />} />
                                <Route path="/bookmark-details" element={<BookmarkDetails />} />
                                <Route path="/news" element={<News />} />
                                <Route path="/news-details" element={<NewsDetails />} />
                                <Route path="/request" element={<Request />} />
                                <Route path="/block-user" element={<BlockUser />} />
                                <Route path="/general" element={<General />} />
                                <Route path="/notification" element={<Notification />} />
                                <Route path="/chat" element={<Chat />} />
                                <Route path="/chat-details" element={<><Socket pageType={'chat'} />, <ChatDetails /></>} />
                                <Route path="/connection" element={<Connection />} />
                                <Route path="/post-detail/:id" element={<PostDetail />} />
                                <Route path="/waiting-page" element={<WaitingPage />} />
                            </Route>
                        </Route>
                    </>

                }
                <Route path="/terms-and-condition" element={<TermsCondition />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes >

        </>
    )
}

export default Router;