import axios from "axios";
import { decrypt, encrypt } from "./encrypt.decrypt";
import Constant from "../config/constant";

export async function request(url, body, method) {

  let token = ''

  const encryptedUser = localStorage.getItem(Constant.AUTH_KEY);
  if(encryptedUser){
    const user = decrypt(encryptedUser)
    if(user && user?.device_info[0]?.token){
      token = user?.device_info[0]?.token
    }
  }

  const requestOptions = {
    method: method,
    url: `${Constant.API_BASE_URL}${url}`,
    headers: {
      "api-key":  encrypt(Constant.API_KEY),
      "token" : encrypt(token),
      "accept-language": "en",
      "access-level" : '2',
      "device_type" : 'W',
      "api_version" : '1.0',
    },
    data: body instanceof FormData ? body : encrypt(body),
  };

  if(!(body instanceof FormData)) {
    requestOptions.headers['Content-Type'] = 'text/plain';
    // requestOptions.headers['Content-Type'] = 'application/json';
  }

  return axios(requestOptions)
    .then((response) => {
      const decryptedData = decrypt(response?.data); 
      console.log(decryptedData,'decryptedData');
      return decryptedData;
      // return response?.data
    })
    .catch((error) => {
      const errors = decrypt(error?.response?.data)
      // console.log(error,'error');
      // const errors = error?.response?.data

      if (errors.code === Constant.UNAUTHORIZED) {
        localStorage.removeItem(Constant.AUTH_KEY);
        window.location.href = "/";
      }
      
    });
}

