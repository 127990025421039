import { Bounce, toast } from "react-toastify";
import Swal from "sweetalert2";
import React from "react";
import moment from "moment";
// import { useDispatch, useSelector } from "react-redux";
// import { getCountryListApiCall } from "../Store/slices/MasterSlice";
// import dayjs from "dayjs";
// import ExcelJS from 'exceljs';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
// import { useTranslation } from "react-i18next";

// const capitalizeWords = (str) => {
//     const hyphenIndex = str.indexOf("-");
//     if (hyphenIndex !== -1) {
//         const words = str.split("-");
//         const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
//         return capitalizedWords.join(" ");
//     } else {
//         return str.charAt(0).toUpperCase() + str.slice(1);
//     }
// };

// const formatDate = (date, format) => {
//     return dayjs(date).format(format);
// };

export const SWIT_SUCCESS = (message) => {
  return Swal.fire({
    position: 'top-center',
    icon: 'success',
    title: message,
    showConfirmButton: false,
    timer: 1800
  })
};

export const SWIT_DELETE = (message) => {
  return Swal.fire({
    title: 'Are you sure?',
    text: message ? message : "",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#5D87FF',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  })
};

export const SWIT_CONFIRM = (message, button) => {
  return Swal.fire({
    title: 'Are you sure?',
    text: message ? message : "",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#5D87FF',
    cancelButtonColor: '#d33',
    confirmButtonText: button || 'yes'
  })
};

export const SWIT_DELETE_SUCCESS = (message) => {
  return Swal.fire({
    position: 'top-center',
    icon: 'success',
    title: message ? message : 'Your data has been deleted.',
    showConfirmButton: false,
    timer: 1500
  })
};

export const SWIT_LOGOUT = (message) => {
  return Swal.fire({
    title: 'Are you sure logout?',
    text: message ? message : "",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#5d87ff',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, logout it!'
  })
};

export const TOAST_SUCCESS = (message) => {
  return toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
  });
};

export const TOAST_INFO = (message) => {
  return toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
  });
};

export const TOAST_ERROR = (message) => {
  return toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
  });
};

export const TOAST_WARNING = (message) => {
  return toast.warning(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
  });
};

export const FORMAT_TIME_AGO = (datetime) => {
  const now = moment();
  const postTime = moment(datetime);
  const diffInSeconds = now.diff(postTime, 'seconds');
  const diffInMinutes = now.diff(postTime, 'minutes');
  const diffInHours = now.diff(postTime, 'hours');
  const diffInDays = now.diff(postTime, 'days');
  const isSameYear = now.isSame(postTime, 'year');

  if (diffInSeconds < 60) {
    return 'just now';
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
  } else if (diffInDays === 1) {
    return 'Yesterday';
  } else if (diffInDays < 7) {
    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
  } else if (isSameYear) {
    return postTime.format('MMM D');
  } else {
    return postTime.format('MMM D, YYYY');
  }
};

export function FORMAT_NUMBER(num) {
  if (num < 1000) {
    return num.toString();
  } else if (num >= 1000 && num < 1000000) {
    return `${(num / 1000).toFixed(1)}k`;
  } else if (num >= 1000000 && num < 1000000000) {
    return `${(num / 1000000).toFixed(1)}m`;
  } else if (num >= 1000000000 && num < 1000000000000) {
    return `${(num / 1000000000).toFixed(1)}b`;
  } else {
    return `${(num / 1000000000000).toFixed(1)}t`;
  }
}

export const WARNING_MODEL = () => {

  return (
    <div className="modal fade" id="al-danger-alert" tabIndex={-1} aria-labelledby="vertical-center-modal" aria-hidden="true">
      <div className="modal-dialog modal-sm">
        <div className="modal-content modal-filled bg-light-danger">
          <div className="modal-body p-4">
            <div className="text-center text-danger">
              <i className="ti ti-hexagon-letter-x fs-7" />
              <h4 className="mt-2">Oh snap!</h4>
              <p className="mt-3">
                Cras mattis consectetur purus sit amet
                fermentum.Cras justo odio, dapibus ac
                facilisis in, egestas eget quam.
              </p>
              <button type="button" className="btn btn-light my-2" data-bs-dismiss="modal">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export const convertToBase64 = async (file) => {
  if (file.type.includes("video")) return URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = reject;
    file && fileReader.readAsDataURL(file);
  });
};

export const renderTaggedUsers = (taggedUsers, location) => {
  const [firstUser, secondUser, ...remainingUsers] = taggedUsers?taggedUsers:[];

  return (
    <>
      {taggedUsers?.length === 0 && location ? 
        location && location!='' ?(<>
            <span className="fw_3"> in </span>{location}
          </>):(<></>) 
        :taggedUsers?.length === 1 && firstUser ? (
        <>
          <span className="fw_3"> is with </span>{firstUser.username}
        </>
      ) : taggedUsers?.length === 2 && firstUser && secondUser ? (
        <>
          <span className="fw_3"> is with </span>{firstUser.username}
          <span className="fw_3"> and </span>{secondUser.username}
        </>
      ) :taggedUsers?.length > 2 && firstUser ? (
        <>
          <span className="fw_3"> is with </span>{firstUser.username}
          <span className="fw_3"> and </span>{secondUser.username}
          <span className="fw_3"> along with </span>{remainingUsers.length} others
        </>
      ):<></>}
      {(taggedUsers?.length !== 0  && location )&& (
        <>
          <span className="fw_3"> in </span>{location}
        </>
      )}
    </>
  );
};

// const ExportToCSV = (data, fileName) => {
//     try {
//         let csvContent = '';
//         if (Array.isArray(data) && data.length > 0) {
//             if (Array.isArray(data[0])) {
//                 csvContent = data.map(row => row.join(",")).join("\n");
//             } else if (typeof data[0] === 'object') {
//                 const headers = Object.keys(data[0]);
//                 csvContent += headers.join(",") + "\n";
//                 csvContent += data.map(row =>
//                     headers.map(header => row[header]).join(",")
//                 ).join("\n");
//             }
//         }
//         const blob = new Blob([csvContent], { type: 'text/csv' });

//         if (window.navigator.msSaveBlob) {
//             window.navigator.msSaveBlob(blob, `${fileName}.csv`);
//         } else {
//             const link = document.createElement("a");
//             if (link.download !== undefined) {
//                 const url = URL.createObjectURL(blob);
//                 link.setAttribute("href", url);
//                 link.setAttribute("download", `${fileName}.csv`);
//                 link.style.visibility = 'hidden';
//                 document.body.appendChild(link);
//                 link.click();
//                 document.body.removeChild(link);
//                 URL.revokeObjectURL(url);
//             } else {
//                 TOAST_ERROR('Your browser does not support downloading files');
//             }
//         }
//     } catch (error) {
//         TOAST_ERROR(error);
//     }
// };

// const ExportToExcel = (data, fileName) => {
//     try {
//         const columns = Object.keys(data[0])?.map(item => item?.replace('_', ' ')?.toUpperCase());
//         const columns1 = Object.keys(data[0]);

//         const wb = new ExcelJS.Workbook();
//         const ws = wb.addWorksheet('Sheet 1');

//         ws.addRow(columns);

//         data.forEach(item => {
//             const row = [];
//             columns1.forEach(column => {
//                 row.push(item[column]);
//             });
//             ws.addRow(row);
//         });

//         wb.xlsx.writeBuffer().then(buffer => {
//             const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//             const link = document.createElement('a');
//             link.href = window.URL.createObjectURL(blob);
//             link.setAttribute('download', `${fileName}.xlsx`);
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//         });
//     } catch (error) {
//         TOAST_ERROR(error);
//     }
// };

// const ExportToPdf = (data, fileName, header) => {
//     try {
//         const doc = new jsPDF();
//         doc.setFontSize(18);
//         doc.text(header, 14, 10);
//         doc.setLineWidth(0.5);
//         doc.line(14, 12, 196, 12);
//         doc.setFontSize(18);

//         const columns = Object.keys(data[0])?.map(item => item?.replace('_', ' ')?.toUpperCase());
//         const tableData = data.map(item => Object.values(item));
//         doc.autoTable(columns, tableData);
//         doc.save(`${fileName}.pdf`);
//     } catch (error) {
//         TOAST_ERROR(error);
//     }
// };



// export const handleFullscreenClick = () => {
//     if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
//         // If already in fullscreen, exit fullscreen
//         if (document.exitFullscreen) {
//             document.exitFullscreen();
//         } else if (document.mozCancelFullScreen) {
//             document.mozCancelFullScreen();
//         } else if (document.webkitExitFullscreen) {
//             document.webkitExitFullscreen();
//         } else if (document.msExitFullscreen) {
//             document.msExitFullscreen();
//         }
//     } else {
//         // If not in fullscreen, request fullscreen
//         if (document.documentElement.requestFullscreen) {
//             document.documentElement.requestFullscreen();
//         } else if (document.documentElement.mozRequestFullScreen) {
//             document.documentElement.mozRequestFullScreen();
//         } else if (document.documentElement.webkitRequestFullscreen) {
//             document.documentElement.webkitRequestFullscreen();
//         } else if (document.documentElement.msRequestFullscreen) {
//             document.documentElement.msRequestFullscreen();
//         }
//     }
// };

export const handleTextChange = (event, setCharCount, setTriggerType, setAllShares, fetchStockList, setDescription, Constant) => {
  const text = event.target.value;
  setCharCount(Constant.CHAR_LIMITS - text.length);
  const lastChar = text.slice(-1);
  if (lastChar === "$") {
      setTriggerType("$");
      setAllShares([]);
  }

  if (setTriggerType && text.includes(setTriggerType)) {
      const query = text.split(setTriggerType).pop()?.trim() || "";
      if (query && !query.includes(" ")) {
          fetchStockList(query);
      }
  } else if (lastChar !== "$") {
      setAllShares([]);
  }

  setDescription(text);
};

export const handleKeyDown = (event, description, triggerType, setDescription, setAllShares,allShares, setTriggerType) => {
  const { keyCode } = event;

  if (keyCode === 8) {
      const cursorIndex = event.target.selectionStart;
      const textBeforeCursor = description.slice(0, cursorIndex);
      const lastTriggerIndex = textBeforeCursor.lastIndexOf("$");

      if (lastTriggerIndex > -1) {
          const textAfterTrigger = textBeforeCursor.slice(lastTriggerIndex + 1);
          if (!textAfterTrigger.includes(" ") || textAfterTrigger.trim() === "") {
              const updatedText = description.slice(0, lastTriggerIndex) + description.slice(cursorIndex);
              setDescription(updatedText);
              setAllShares([]);
              setTriggerType("");
              return;
          }
      }
  } else if (keyCode === 32) {
      if (triggerType) {
          const lastTriggerIndex = description.lastIndexOf("$");
          const textAfterTrigger = description.slice(lastTriggerIndex + 1).trim();
          if (textAfterTrigger === "" || !allShares.some((item) => item.symbol.startsWith(textAfterTrigger))) {
              const updatedText = description.slice(0, lastTriggerIndex) + " ";
              setDescription(updatedText);
              setAllShares([]);
              setTriggerType("");
          }
      }
  }
};

export const handleSelectionChange = (event, setSelection) => {
  const { selectionStart, selectionEnd } = event.target;
  setSelection({ start: selectionStart, end: selectionEnd });
};

export const handleItemSelect = (item, description, triggerType, setDescription, setAllShares, setTriggerType) => {
  const updatedText =
    description.slice(0, description.lastIndexOf(triggerType)) +
    triggerType +
    item.symbol +
    " ";
  setDescription(updatedText);
  setAllShares([]);
  setTriggerType("");
};
