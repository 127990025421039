import { request } from "../utils/axios.services";


const USER = '/user';
const BOOKMARK = '/bookmark';
const CHAT = '/chat';
const SOCIAL_CONNECT = '/social_connect';
const POST = '/post';
const STOCK = '/stock';
const SEARCH = '/search';
const NOTIFICATION = '/notification';
const SUBSCRIPTION = '/subscription';


export function login(data) {
    return request(`${USER}/login`, data, 'POST');
}

export function sendOtp(data) {
    return request(`${USER}/send_otp`, data, 'POST');
}

export function resetPassword(data) {
    return request(`${USER}/reset_password`, data, 'POST');
}

export function signUp(data) {
    return request(`${USER}/signup`, data, 'POST');
}

export function complateProfile(data) {
    return request(`${USER}/complate_profile`, data, 'POST');
}

export function logout(data) {
    return request(`${USER}/logout`, {}, 'POST');
}

export function getUserDetails(data) {
    return request(`${USER}/get_user_details`, data, 'POST');
}

export function updateProfile(data) {
    return request(`${USER}/update_profile`, data, 'POST');
}

export function updatePassword(data) {
    return request(`${USER}/update_password`, data, 'POST');
}

export function getContentData(data) {
    return request(`${USER}/get_content_data`, data, 'POST');
}

export function contactUs(data) {
    return request(`${USER}/contact_us`, data, 'POST');
}

export function subscriptionList(data) {
    return request(`${USER}/subscription_list`, {}, 'POST');
}

export function buySubscription(data) {
    return request(`${USER}/buy_subscription`, data, 'POST');
}

export function deleteAccount(data) {
    return request(`${USER}/delete_account`, data, 'POST');
}

export function addBookmark(data) {
    return request(`${BOOKMARK}/add`, data, 'POST');
}

export function updateBookmark(data) {
    return request(`${BOOKMARK}/update`, data, 'POST');
}

export function deleteBookmark(data) {
    return request(`${BOOKMARK}/delete`, data, 'POST');
}

export function listBookmark(data) {
    return request(`${BOOKMARK}/list`, data, 'POST');
}

export function addPostToBookmark(data) {
    return request(`${BOOKMARK}/add_post_to_bookmark`, data, 'POST');
}

export function removePostToBookmark(data) {
    return request(`${BOOKMARK}/remove_post_from_bookmark`, data, 'POST');
}

export function listBookmarkPost(data) {
    return request(`${BOOKMARK}/list_bookmark_post`, data, 'POST');
}

export function sendMultiplePost(data) {
    return request(`${BOOKMARK}/send_post`, data, 'POST');
}

export function chatList(data) {
    return request(`${CHAT}/get_chat_friend_list`, data, 'POST');
}

export function getMessageList(data) {
    return request(`${CHAT}/get_chat`, data, 'POST');
}

export function sendMessage(data) {
    return request(`${CHAT}/send_message`, data, 'POST');
}

export function readMessage(data) {
    return request(`${CHAT}/read_message`, data, 'POST');
}

export function socialConnectList(data) {
    return request(`${SOCIAL_CONNECT}/list`, data, 'POST');
}

export function updateRequestStatus(data) {
    return request(`${SOCIAL_CONNECT}/update_request_status`, data, 'POST');
}

export function cancelRequest(data) {
    return request(`${SOCIAL_CONNECT}/cancel_request`, data, 'POST');
}

export function manageBlockUnblockStatus(data) {
    return request(`${SOCIAL_CONNECT}/manage_block_unblock_status`, data, 'POST');
}

export function sendRequest(data) {
    return request(`${SOCIAL_CONNECT}/send_request`, data, 'POST');
}

export function manageUnfollowStatus(data) {
    return request(`${SOCIAL_CONNECT}/manage_unfollow_status`, data, 'POST');
}

export function getSocialCount(data) {
    return request(`${SOCIAL_CONNECT}/get_count`, data, 'POST');
}

export function reportUser(data) {
    return request(`${SOCIAL_CONNECT}/report_user`, data, 'POST');
}

export function postList(data) {
    return request(`${POST}/list`, data, 'POST');
}

export function postLikeUnlike(data) {
    return request(`${POST}/post_like_unlike`, data, 'POST');
}

export function commentList(data) {
    return request(`${POST}/comment_list`, data, 'POST');
}

export function postComment(data) {
    return request(`${POST}/post_comment`, data, 'POST');
}

export function addPost(data) {
    return request(`${POST}/add`, data, 'POST');
}

export function updatePost(data) {
    return request(`${POST}/update`, data, 'POST');
}

export function deletePost(data) {
    return request(`${POST}/delete`, data, 'POST');
}

export function stockDetail(data) {
    return request(`${STOCK}/stock_detail`, data, 'POST');
}

export function stockList(data) {
    return request(`${STOCK}/stock_list`, data, 'POST');
}

export function newsList(data) {
    return request(`${STOCK}/news_list`, data, 'POST');
}

export function getSearchStockList(data) {
    return request(`${STOCK}/search_stock`, data, 'POST');
}

export function addRemoveStockToWatchlist(data) {
    return request(`${STOCK}/add_remove_stock_to_watchlist`, data, 'POST');
}

export function getStockHistoricalData(data) {
    return request(`${STOCK}/get_stock_historical_data`, data, 'POST');
}

export function getSearchUserStockList(data) {
    return request(`${SEARCH}/list`, data, 'POST');
}

export function addToSearchList(data) {
    return request(`${SEARCH}/add`, data, 'POST');
}

export function getNotificationList(data) {
    return request(`${NOTIFICATION}/list`, data, 'POST');
}

export function purchaseSubscription(data) {
    return request(`${SUBSCRIPTION}/purchase_subscription`, data, 'POST');
}

export function buySubscriptionPlan(data) {
    return request(`${SUBSCRIPTION}/buy_subscription`, data, 'POST');
}