import { createContext, useEffect, useState } from "react";
import Constant from "../config/constant";
import { decrypt } from "../utils/encrypt.decrypt";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [is_login, setIsLogin] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const encryptedUser = localStorage.getItem(Constant.AUTH_KEY) || '';
    const user = encryptedUser ? decrypt(encryptedUser) : null;

    if (encryptedUser) {
      if (user?.device_info?.find(device => device.device_type === "W" && device.token !== "")) {
        setUser(user)
        if(user?.step_number === 2){
          setIsLogin(true);
        }
      }
    }
  }, []);

  const updateUser = (is_login=true) =>{
    const encryptedUser = localStorage.getItem(Constant.AUTH_KEY) || '';
    const user = encryptedUser ? decrypt(encryptedUser) : null;

    if (encryptedUser) {
      if (user?.device_info?.find(device => device.device_type === "W" && device.token !== "")) {
        setUser(user)
        setIsLogin(is_login);
      }
    }else{
      setUser({})
      setIsLogin(false);
    }
  }
  
  return (
    <UserContext.Provider value={{ user, is_login , updateUser}}>
      {children}
    </UserContext.Provider>
  );
};
