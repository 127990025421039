import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth"
import { getMessaging, getToken } from "firebase/messaging"
import { TOAST_WARNING } from '../config/common';

const firebaseConfig = {
    apiKey: "AIzaSyAE1Qni6mrjweJJ6KSwUOSz5Cb0YMAMnCM",
    authDomain: "optimal-timer-441114-i0.firebaseapp.com",
    projectId: "optimal-timer-441114-i0",
    storageBucket: "optimal-timer-441114-i0.firebasestorage.app",
    messagingSenderId: "539542806397",
    appId: "1:539542806397:web:1b52ea96a46527014c7614",
    measurementId: "G-2K8X5R49QH"
};

initializeApp(firebaseConfig);
export const auth = getAuth()

// Google Part
export const google = new GoogleAuthProvider()
google.addScope('email')

// Facebook Part
export const facebook = new FacebookAuthProvider()
facebook.addScope('email')

export const apple = new OAuthProvider('apple.com');
apple.addScope('email');
apple.addScope('name');

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const generateToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const token = await getToken(messaging, {
                vapidKey: "BAu2OSdZtvbl_zrFQE6KzvLxyA_t6Qoi7gTq0la4AajjtQ-iMtlErokYnduOowqCJIjruTWj4pAfsP_vIQGja08"
            });
            return token;
        } else {
            TOAST_WARNING('Notification permission denied');
            return '0';
        }
    } catch (error) {
        TOAST_WARNING('Notification permission denied');
        return '0';
    }
};